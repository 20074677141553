import { Menu, MenuProps } from "antd"
import { observer } from "mobx-react-lite"
import { FC, useContext, useEffect, useState } from "react"
import { FormattedMessage } from "react-intl"
import { useLocation, useNavigate } from "react-router-dom"
import { Context } from ".."
import useWindowDimensions from "../core/hooks/useWindowDimensions"
import { LOCALES } from "../core/i18n/locales"
import { isNullOrUndefined, translit } from "../core/utils/utils"

import styles from "../styles/Header.module.scss"

interface HeaderProps {
  isMobile?: boolean
  onOpenMenu?: any
  goToSection?: any
  onBackCall?: any
}

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: 'group',
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as MenuItem;
}

const Header: FC<HeaderProps> = ({
  onOpenMenu = null, goToSection = null, onBackCall = null, isMobile = false
}) => {

  const { store, shopStore, studioStore, adminStore } = useContext(Context)
  const { width, height } = useWindowDimensions()
  const navigate = useNavigate()
  const location = useLocation()
  
  const [menuPreOpenned, setMenuPreOpenned] = useState(true)
  const [searchGoVisible, setSearchGoVisible] = useState(false)
  
  const [headerToggled, setHeaderToggled] = useState(true)
  const [headerTgl, setHeaderTgl] = useState(false)

  const [isMenuShown, setIsMenuShown] = useState(false)
  const [isMenuVisible, setIsMenuVisible] = useState(false)
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  
  const [items, setItems] = useState([] as MenuProps['items'])

  const onMouseUp = (e: any) => {
    var container = document.getElementById("ddp_menu")

    console.log("PRE-FIRREEEE", e)
    if (container != null && !container.contains(e.target)) {
      //container.style.display = 'none'
      console.log("FIRREEEE!!!", e)
      closeMenu()
    }
  }

  const openMenu = () => {
    setIsMenuShown(prev => prev = true)
    // if (width < 1280) {
      // document.getElementById("root")!.style.overflowY = "hidden"
      // document.getElementsByTagName("html")[0].style.overflowY = "hidden"
      // document.body.style.overflowY = "hidden"

      // document.addEventListener('mouseup', onMouseUp)
    // }
    setIsMenuVisible(prev => prev = true)
    setTimeout(() => {
      setIsMenuOpen(prev => prev = true)
    }, 100)
  }

  const closeMenu = () => {
    setIsMenuOpen(prev => prev = false)
    // if (width < 1280) {
      // document.getElementById("root")!.style.overflowY = "scroll"
      // document.getElementsByTagName("html")[0].style.overflowY = "hidden"
      // document.body.style.overflowY = "hidden"

      // document.removeEventListener('mouseup', onMouseUp)
    // }
    setTimeout(() => {
      setIsMenuVisible(prev => prev = false)
      setIsMenuShown(prev => prev = false)
    }, 300)
  }

  const goTop = () => {
    if (store.menuSelected != 0) {
      store.setIsHomeBlockVisible(false);
      store.setIsHeaderLocked(false);
      store.setSelectedSlide(0);
      setTimeout(() => {
        store.setMenuSelected(0);
        setTimeout(() => {
          store.setIsHomeBlockVisible(true);
        }, 100);
      }, 200);
    }

    const section = document.querySelector(`#top`);
    if (!isNullOrUndefined(section)) {
      section.scrollIntoView({
        behavior: 'smooth'
      });
    }
  }

  const navTo = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    
    let path = "";

    if (event.target.nodeName.toLowerCase() == "img") {
      path = event.target.parentElement.pathname;
    } else {
      path = event.target.pathname;
    }

    console.log(path, location.pathname, event);

    if (path == location.pathname) {
      return
    }

    store.setIsFaderVisible(true)
    setTimeout(() => {
      store.setIsFaderShown(true)

      window.scroll(0, 0)

      setTimeout(() => {
        navigate(path)
      }, 300)
    }, 100)
  }

  const isHeaderBg = () => {
    return location.pathname == "/contacts" || location.pathname == "/about"
  }

  const runAnimations = () => {
    const logo = document.getElementById("logo")
    const open = document.getElementById("open")
    const menu1 = document.getElementById("menu1")
    const menu2 = document.getElementById("menu2")
    const menu3 = document.getElementById("menu3")
    const menu4 = document.getElementById("menu4")
    const menu5 = document.getElementById("menu5")
    const soc1 = document.getElementById("soc1")
    const soc2 = document.getElementById("soc2")
    const act1 = document.getElementById("act1")

    if (logo != null) {
      logo.style.opacity = "1"
    }

    if (open != null) {
      setTimeout(() => {
        open.style.opacity = "1"
      }, 200)
    }

    if (
      menu1 != null &&
      menu2 != null &&
      menu3 != null &&
      menu4 != null &&
      menu5 != null
    ) {
      setTimeout(() => {
        menu1.style.opacity = "1"
        setTimeout(() => {
          menu2.style.opacity = "1"
          setTimeout(() => {
            menu3.style.opacity = "1"
            setTimeout(() => {
              menu4.style.opacity = "1"
              setTimeout(() => {
                menu5.style.opacity = "1"
              }, 60)
            }, 60)
          }, 60)
        }, 60)
      }, 100)
    }

    if (soc1 != null && soc2 != null && act1 != null) {
      act1.style.opacity = "1"
      setTimeout(() => {
        soc1.style.opacity = "1"
        setTimeout(() => {
          soc2.style.opacity = "1"
        }, 100)
      }, 100)
    }
  }

  const openForm = (title: string) => {
    store.setFormTitle(title)
    store.setIsFormVisible(true)
    closeMenu()
  }

  useEffect(() => {
    let scrFix = 0;

    setTimeout(() => {
      runAnimations()
    }, 100)

    const onScroll = (event: any) => {
      const scrollTop = event.target.scrollTop
      
      // if (width <= 960 && location.pathname == "/") {
      //   return
      // }

      if (scrollTop >= 60) {
        store.setIsHeaderLocked(true)
      } else {
        store.setIsHeaderLocked(false)
      }

      console.log(store.xScr)

      if (scrollTop < scrFix && scrollTop > store.xScr) {
        setHeaderToggled(prev => prev = true)
      } else if (scrollTop > store.xScr) {
        setHeaderToggled(prev => prev = false)
      }

      scrFix = parseInt(`${scrollTop}`);
      
      if (location.pathname.includes("/projects") && store.selectedProject != null) {
        if (scrollTop > window.innerHeight - 50) {
          setHeaderTgl(prev => prev = true)
        } else {
          setHeaderTgl(prev => prev = false)
        }
      } else {
        setHeaderTgl(prev => prev = false)
      }
    }

    shopStore.getGlobalSearchText()
    
    document.getElementById("root")!.addEventListener('scroll', onScroll)

    return () => {
      document.getElementById("root")!.removeEventListener('scroll', onScroll)
    }
  }, [])

  return (
    <>
      <div className={styles.menu_wrap} style={{
        display: isMenuVisible ? "block" : "none",
        opacity: isMenuOpen ? "1" : "0"
      }}>
        <div className={styles.logo}>
          <a href="/">PODCASTBERY</a>
        </div>
        <div className={styles.close} onClick={() => closeMenu()}><i className="fas fa-xmark"></i></div>
        <div className={styles.menu_items}>
          <a className={styles.menu_items__item + (location.pathname == "/" ? " " + styles.active : "")} href="/">Главная</a>
          <a className={styles.menu_items__item + (location.pathname.includes("/studios") ? " " + styles.active : "")} href="/studios">Продакшн студии</a>
          <a className={styles.menu_items__item + (location.pathname.includes("/connect") ? " " + styles.active : "")} href="/connect">Стать партнером</a>
          {/* <a className={styles.menu_items__item + (location.pathname.includes("/blog") ? " " + styles.active : "")} href="/blog">Блог</a>
          <a className={styles.menu_items__item + (location.pathname.includes("/podcasts") ? " " + styles.active : "")} href="/podcasts">Подкасты</a> */}
          {/* <a className={styles.menu_items__item + (location.pathname.includes("/about") ? " " + styles.active : "")} href="/about">О проекте</a> */}
        </div>
        <div className={styles.actions}>
          <a href="/basket"><i className="fas fa-cart-shopping"></i></a>
          <a href="/account"><i className="fas fa-heart"></i></a>
          <a href="/favorites"><i className="fas fa-user"></i></a>
        </div>
      </div>

      <header className={styles.header + (store.isHeaderLocked ? " " + styles.fixed : "")}>
        <div className="container">
          <div className="row">
            <div className={width > 960 ? (width > 1160 ? "col-3 " : "col-3 ") : "col-6"}>
              <a href="/" className={styles.logo}>PODCASTBERY</a>
            </div>
            {width > 960 ? <div className={(width > 1160 ? "col-6 " : "col-7 ") + styles.flexed_center}>
              <div className={styles.menu}>
                <a href="/" className={location.pathname == "/" ? styles.active : ""}>Главная</a>
                <a href="/studios" className={location.pathname.includes("/studios") ? styles.active : ""}>Продакшн студии</a>
                <a href="/connect" className={location.pathname.includes("/connect") ? styles.active : ""}>Стать партнером</a>
                {/* <a href="/blog" className={location.pathname.includes("/blog") ? styles.active : ""}>Блог</a>
                <a href="/podcasts" className={location.pathname.includes("/podcasts") ? styles.active : ""}>Подкасты</a> */}
                {/* <a href="/about" className={location.pathname.includes("/about") ? styles.active : ""}>О проекте</a> */}
              </div>
            </div> : <></>}
            {width > 960 ? <div className={(width > 960 ? (width > 1160 ? "col-3 " : "col-2 ") : "col-6 ") + styles.ended_wrap}>
              {store.isAuth ? <div className={styles.account_btns}>
                {/* <a href="/account"><i className="fas fa-book-heart"></i></a> */}
                <a href="/account">Личный кабинет</a>
              </div> : <div className={styles.account_btns}>
                <a href="/signin">Войти</a>
                <a href="/signup">Регистрация</a>
              </div>}
            </div> : <></>}
            <div className={(width > 960 ? (width > 1160 ? "col-3 " : "col-2 ") : "col-6 ") + styles.ended_wrap}>
              {width < 960 ? <button className={styles.menu_btn} onClick={() => openMenu()}><i className="far fa-bars"></i></button> : <></>}
            </div>

            <div className={styles.divider}></div>
          </div>
          
        </div>
      </header>
    </>
  )
}

export default observer(Header)