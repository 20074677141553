import { observer } from "mobx-react-lite"
import { FC, useContext, useEffect, useState } from "react"
import {FormattedMessage, useIntl} from "react-intl";
import { useLocation, useNavigate } from "react-router-dom";
import { Context } from "..";
import useWindowDimensions from "../core/hooks/useWindowDimensions";
import {LOCALES} from "../core/i18n/locales";
import {isElementInViewport, stripPhone} from "../core/utils/utils";

import styles from "../styles/Footer.module.scss"

const Footer: FC = () => {

  const { store, adminStore } = useContext(Context)

  const location = useLocation()
  const navigate = useNavigate()
  const { width } = useWindowDimensions()
  
  const [isVisible, setIsVisible] = useState(false)
  const [footerVisible, setFooterVisible] = useState(false)
  const [isToTopVisible, setIsToTopVisible] = useState(false)
  
  const toTop = () => {
    window.scrollTo({
      behavior: "smooth",
      top: 0
    })
  }

  const navTo = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    
    let path = "", nodeName = event.target.nodeName.toLowerCase()

    if (nodeName == "img" || nodeName == "span") {
      path = event.target.parentElement.pathname;
    } else {
      path = event.target.pathname;
    }

    console.log(path, location.pathname, event);

    if (path == location.pathname) {
      return
    }

    store.setIsFaderVisible(true)
    setTimeout(() => {
      store.setIsFaderShown(true)

      setTimeout(() => {
        navigate(path)
      }, 300)
    }, 100)
  }

  useEffect(() => {
    const onScroll = (event: any) => {
      const scrollTop = event.target.scrollTop;
      if (scrollTop >= 80) {
        setIsToTopVisible(true);
      } else {
        setIsToTopVisible(false);
      }

      // if (width > 768) {
        const ftr = document.getElementById("ftr")
        if (ftr != null && isElementInViewport(ftr)) {
          setFooterVisible(prev => prev = true)
        }
      // }
    }

    // if (width > 768) {
      setTimeout(() => {
        setIsVisible(prev => prev = true)
        onScroll({target: {scrollTop: 0}})
      }, 400)
    // } else {
    //   setIsVisible(prev => prev = true)
    //   setFooterVisible(prev => prev = true)
    // }

    document.getElementById("root")!.addEventListener('scroll', onScroll);

    return () => {
      document.getElementById("root")!.removeEventListener('scroll', onScroll);
    }
  }, [])

  return (
    <>
      <div className={styles.footer}>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className={styles.footer_wrap}>
                <div className={styles.fw_top}>
                  <div className={styles.fw_top_logo}>
                    <a className={styles.alogo} href="/">PODCASTBERY</a>

                    <div className={styles.contacts}>
                      <a href="mailto:info@castbery.ru" target="_blank">info@castbery.ru</a>
                      <a href="tel:+79999999999">+7 (999) 999-99-99</a>
                    </div>

                    <div className={styles.socials}>
                      <a href="#" target="_blank"><i className="fab fa-instagram"></i></a>
                      <a href="#" target="_blank"><i className="fab fa-telegram"></i></a>
                      <a href="#" target="_blank"><i className="fab fa-whatsapp"></i></a>
                    </div>
                  </div>
                  <div className={styles.fw_top_menus}>
                    <div className={styles.fw_top_menu}>
                      <h4>ДЛЯ КЛИЕНТОВ</h4>
                      <a href="/studios">Продакшн студии</a>
                      <a href="/podcasts">Новые подкасты</a>
                      <a href="/blog">Блог</a>
                      {/* <a href="/about">О проекте</a> */}
                    </div>
                    <div className={styles.fw_top_menu}>
                      <h4>ДЛЯ СТУДИЙ</h4>
                      <a href="/connect">Подать заявку</a>
                      <a href="/partner">Личный кабинет партнера</a>
                      <a href="/rules">Правила сервиса</a>
                    </div>
                  </div>
                </div>
                <div className={styles.fw_bottom}>
                  <div className={styles.fw_bottom__copys}>© 2024 «ПОДКАСТБЕРИ»</div>
                  <div className={styles.fw_bottom__links}>
                    <a href="/policy">Политика конфиденциальности</a>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="col-12 col-md-4">
              <div className={styles.logo}>
                <img src="/assets/images/logo.svg" />
              </div>
              <div className={styles.flexedd}>
                <div className={styles.copys}>
                  <p>© 2004-2024 «Фермерские истории»</p>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-8">
            </div>
            <div className="col-12">
              <div className={"row " + styles.flexed}>
                <div className={"col-12 col-md-12 " + styles.flx}>
                  <div className={styles.xlinks}>
                    <a href="https://fermstory.ru" target="_blank">Наш сайт</a>
                    <a href="/policy">Политика конфиденциальности</a>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </>
  )
}

export default observer(Footer)
